
import { defineComponent, ref, onMounted, computed, watchEffect, onUnmounted } from "vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import InvoiceService from "@/core/services/car/InvoiceService";
import InvoiceAction from "@/components/dropdown/car/InvoiceAction.vue";
import ShowQuoteRefNo from "@/components/reusables/showQuoteRefNo.vue";
import ViewDocumentsModal from "@/components/modals/forms/ViewDocumentsModal.vue";
import mainSocket from "./../../../main-socket";
import PolicyModal from "./PolicyModal.vue";
import { Mutations } from "@/store/enums/StoreEnums";
import LocalStorageServices from "@/core/services/LocalStorageServices";
import UploadInvoiceDocument from "@/components/modals/forms/UploadInvoiceDocument.vue";
import router from "@/router";
import { onBeforeRouteLeave } from "vue-router";
import {customFunction, roles} from "@/store/stateless/store";
// import Overlay from "vuejs-overlay";
// import 'jquery/dist/jquery.min.js';
//Datatable Modules
// import "datatables.net-dt/js/dataTables.dataTables"
// import "datatables.net-dt/css/jquery.dataTables.min.css"
// import 'bootstrap/dist/css/bootstrap.min.css';
// import $ from 'jquery';

interface InvoiceBodyParam {
  per_page: string;
  agent: string;
  payment_type: any;
  is_amend: number;
  status: any;
  is_discrepancy: number;
  keyword: string;
  start_date: string;
  end_date: string;
  is_manual_order: any;
}

interface ApproveInvoiceParam {
  invoice_id: number;
  status: any;
}

export default defineComponent({
  name: "policy-approval",
  components: {
    InvoiceAction,
    PolicyModal,
    ViewDocumentsModal,
    UploadInvoiceDocument,
    ShowQuoteRefNo
    // Overlay,
  },
  data() {
    return {
      // opened: false,
      // visible: false,
      shortcuts: [
        {
          text: "Last week",
          value: (() => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            return [start, end];
          })()
        },
        {
          text: "Last month",
          value: (() => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            return [start, end];
          })()
        },
        {
          text: "Last 3 months",
          value: (() => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            return [start, end];
          })()
        }
      ],
      value1: "",
      value2: "",
      invoiceStatus: [
        { id: 1, name: "Pending" },
        { id: 2, name: "Approve" },
        { id: 3, name: "Decline" }
      ],
      invoiceOnlineStatus: [
        { id: 1, name: "Pending" },
        // { id: 2, name: "Approve" },
        { id: 3, name: "Decline" }
      ],
      invoiceDescripancyStatus: [
        { id: 1, name: "For Discrepancy approval " },
        // { id: 2, name: "Approve" },
        // { id: 3, name: "Decline" },
        { id: 4, name: "Approve with Discrepancy" },
        { id: 5, name: "Update Discrepancy" }
      ]
    };
  },
  setup() {
    // let customers_obj = ref([]);
    let responseResult = ref<any>({});
    let showAction = ref<boolean>(true);
    responseResult.value.success = false;
    responseResult.value.failed = false;
    responseResult.value.execute = false;
    let approveInvoiceParam = {} as ApproveInvoiceParam;
    const store = useStore();
    let userObj: any = {};
    let bodyParameters = {} as InvoiceBodyParam;
    let exportUrl = ref<string>("");
    let isManualOrder = ref<boolean>(false);
    // let invoiceListCount = ref({});

    bodyParameters = {
      per_page: "50",
      agent: "all",
      payment_type: [2, 3, 4],
      is_amend: -1,
      is_discrepancy: 0,
      status: 1,
      keyword: "",
      start_date: "",
      end_date: "",
      is_manual_order: 1
    };

    const openModal = computed(() => {
      return store.getters.getModal;
    });
    let getActiveTab = computed(() => {
      return store.getters.getQuickLinkTab;
    });
    const closeModal = (e) => {
      if(typeClick.value != 'discrepancy') e.item.status = 1;
      if(typeClick.value == 'discrepancy') e.item.is_discrepancy = 1;
      
      e.item.status = 1;
      isError.value = false;
      store.commit(Mutations.SET_MODAL, false);
    };

    const isError = ref<boolean>(false);

    const ProceedModal = (e) => {
      isError.value = false;
      store.commit(Mutations.SET_MODAL_LOADING);
      const item = e;
      Promise.all([InvoiceService.updateInvoice(e)])
        .then((data) => {
          store.commit(Mutations.SET_MODAL_LOADING);
          store.commit(Mutations.SET_MODAL, false);
          
          if(data[0].data.success !== undefined && !data[0].data.success)
          {
            store.commit(Mutations.SET_MODAL, true);
            store.commit(Mutations.SET_MODAL_VALUE, {
              item,
              invoice_id: item.id,
              status: '0'
            });
          }
          else
          {
            fetchInvoiceList(bodyParameters);
          }

          // notif.simple("Invoice Approval", "success", `You have successfully ${e.status === "2" ? 'approve' : (e.status === "3" ? 'decline' : (e.status === "4" ? 'approve with discrepancy' : 'update discrepancy'))} the invoice`)
        })
        .catch((err) => {
          isError.value = true;
          store.commit(Mutations.SET_MODAL_LOADING);
        });
    };

    const loading = ref<boolean>(true);

    onMounted(() => {
      if(!roles.forApproval.includes(roles.userId())) window.location.href = '/#/404'
      
      
      MenuComponent.reinitialization();

      setCurrentPageBreadcrumbs("Invoices For Approval", ["Invoice"]);

      // API Action to get customers list
      bodyParameters.keyword = "";
      bodyParameters.is_manual_order = 0;
      console.log(router.currentRoute.value.query.type);
      if (
        router.currentRoute.value.query &&
        router.currentRoute.value.query.type == "manual-order"
      ) {
        bodyParameters.is_manual_order = 1;
        bodyParameters.payment_type = [2, 3];
      }
      fetchInvoiceList(bodyParameters);
      const currentUserId = user.value.id;
      mainSocket
        .channel("channel-" + currentUserId)
        .listen("CarInvoiceSocketEvent", (response) => {
          if (response.invoice_status) {
            fetchInvoiceList(bodyParameters, false);
          }
        });

        // setTimeout(() => {
        //   fetchInvoiceList(bodyParameters, false);
        // }, 10000);
    });

    const getPaginationUrl = (index) => {
      // let pagenationUrl = index.split("=");
      window.scrollTo({ top: 0, behavior: 'smooth' })
      bodyParameters.keyword = "";
      InvoiceService.fetchInvoiceList(bodyParameters, currentPage.value);
    };
    const onPerPageChange = (event) => {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      bodyParameters.per_page = event.target.value;
      fetchInvoiceList(bodyParameters);
    };

    const fetchInvoiceListFor = (listFor) => {
      const user = store.getters.currentUser;
      bodyParameters.agent = listFor;
      bodyParameters.keyword = "";
      bodyParameters.agent =
        user.role_id == 1 || user.role_id == 6 ? "all" : "mine";
      fetchInvoiceList(bodyParameters);
    };

    const policyModalRef = ref()
    const typeClick = ref('');
    const apporvePolicy = (item, event, type) => {
      typeClick.value = type
      if (
        type == "discrepancy" &&
        event.target.value != 2 &&
        event.target.value != 1
      ) {
        store.commit(Mutations.SET_MODAL, true);
        store.commit(Mutations.SET_MODAL_VALUE, {
          item,
          invoice_id: item.id,
          status: event.target.value
        });
        policyModalRef.value.toFirst()
      }
      
      if (type == "standard" && event.target.value != 1) {
        store.commit(Mutations.SET_MODAL, true);
        store.commit(Mutations.SET_MODAL_VALUE, {
          item,
          invoice_id: item.id,
          status: event.target.value
        });
        policyModalRef.value.toFirst()
      }

      
      // approveInvoiceParam.invoice_id = invoice_id;
      // approveInvoiceParam.status = parseInt(event.target.value);
      // Promise.all([InvoiceService.updateInvoice(approveInvoiceParam)]).then(
      //   (data) => {
      //     fetchInvoiceList(bodyParameters);
      //   }
      // );
    };
    const fetchInvoiceList = (bodyParameters, reload = true) => {
      currentPage.value = 1;
      const user = store.getters.currentUser;
      bodyParameters.agent =
        user.role_id == 1 || user.role_id == 6 ? "all" : "mine";

      bodyParameters.reload = reload
      InvoiceService.fetchInvoiceList(bodyParameters)
        .then(() => {
          loading.value = false;
          Promise.all([
            InvoiceService.getApprovalInvoiceListCount(bodyParameters),
          ])
        })
        .catch(() => {
          loading.value = false;
        });
    };
    const fetchInvoiceListPeriod = (listPeriod) => {
      if(listPeriod) {
        var startTS = new Date(listPeriod[0]);
      var endTS = new Date(listPeriod[1]);
      bodyParameters.start_date =
        startTS.getFullYear() +
        "-" +
        (startTS.getMonth() + 1) +
        "-" +
        startTS.getDate();
      bodyParameters.end_date =
        endTS.getFullYear() +
        "-" +
        (endTS.getMonth() + 1) +
        "-" +
        endTS.getDate();
      } else {
        bodyParameters.start_date = '';
        bodyParameters.end_date = '';
      }
      // bodyParameters.keyword = "";
      fetchInvoiceList(bodyParameters);
    };

    const search = ref<string>("");
    const searchInvoice = () => {
      bodyParameters.keyword = search.value;
      //
      fetchInvoiceList(bodyParameters);
    };
    const activeLink = ref('all')
    const resetBodyParam = () => {
      search.value = "";
      bodyParameters.per_page = "50";
      bodyParameters.agent = "all";
      bodyParameters.payment_type = [2, 3, 4];
      bodyParameters.is_amend = -1;
      bodyParameters.is_discrepancy = 0;
      bodyParameters.status = 1;
      bodyParameters.keyword = "";
      bodyParameters.start_date = "";
      bodyParameters.end_date = "";
      bodyParameters.is_manual_order = 0;
      activeLink.value = 'all';
      showAction.value = true;

      fetchInvoiceList(bodyParameters);
    };
    const exportTransactionApprovals = () => {
      Promise.all([
        InvoiceService.exportTransactionApprovals(bodyParameters)
      ]).then((data) => {
        console.log(data);
        let exportApproval = computed(() => {
          return store.getters.getExportApproval;
        });
        exportUrl.value = exportApproval.value;
      });
    };
    const codLoaded = ref(false)
    const getCodDirectIntrasact = () => {
      showAction.value = true;
      activeLink.value = 'all';
      isManualOrder.value = false;
      bodyParameters.is_discrepancy = 0;
      bodyParameters.status = 1;
      bodyParameters.payment_type = [2, 3, 4];
      bodyParameters.is_manual_order = 0;
      console.log(bodyParameters);
      fetchInvoiceList(bodyParameters);
      codLoaded.value = true
    };
    const discrepancyLoaded = ref(false)
    const getDiscrepancy = () => {
      activeLink.value = 'discrepancy';
      console.log("bodyParameters------------");
      isManualOrder.value = false;
      bodyParameters.is_discrepancy = 1;
      bodyParameters.status = -1;
      bodyParameters.payment_type = [1, 2, 3];
      bodyParameters.is_manual_order = [0, 1];
      showAction.value = true;
      console.log(bodyParameters);
      fetchInvoiceList(bodyParameters);
      discrepancyLoaded.value = true
    };
    const onlineLoaded = ref(false)
    const getOnline = () => {
      activeLink.value = 'online';
      console.log("bodyParameters------------");
      isManualOrder.value = false;
      bodyParameters.is_discrepancy = 0;
      bodyParameters.status = 1;
      bodyParameters.payment_type = [1];
      bodyParameters.is_manual_order = [0, 1];
      showAction.value = true;
      console.log(bodyParameters);
      fetchInvoiceList(bodyParameters);
      onlineLoaded.value = true
    };
    const getManualOrders = () => {
      activeLink.value = 'manual';
      isManualOrder.value = true;
      bodyParameters.is_discrepancy = 0;
      bodyParameters.status = 1;
      bodyParameters.payment_type = [2, 3];
      bodyParameters.is_manual_order = 1;
      showAction.value = true;
      console.log(bodyParameters);
      fetchInvoiceList(bodyParameters);
    };

    const getFailed = () => {
      activeLink.value = 'failed';
   
      isManualOrder.value = false;
      bodyParameters.is_discrepancy = 0;
      bodyParameters.status = 6;
      bodyParameters.payment_type = [1,2,3,4];
      bodyParameters.is_manual_order = [0, 1];
      showAction.value = false;
     
      fetchInvoiceList(bodyParameters);
    };
    
    let disableSaveButton = computed(() => {
      return store.getters.getDisableButtonStatus;
    });
    let loadApprovalInformation = computed(() => {
      return store.getters.getApprovalListingDom;
    });
    const userPermission = computed(() => {
      return store.getters.getUserPermissions;
    });

    const user = computed(() => {
      userObj = LocalStorageServices.getUser();
      if (userObj == null) {
        userObj = {};
        return userObj;
      }
      return JSON.parse(userObj);
    });
    const invoiceList = computed(() => {
      const data = store.getters.getInviceList;

      
      return data
        .map((val) => {
          return {
            status: 1, ...val
          };
        })
        .filter((x) => {
          return x.customer != null;
        });
    });

    const paginatioObject = computed(() => {
      return store.getters.getInvoicePaginationObject;
    });

    const currentPage = ref(1);
    const IFAloading = computed(() => {
        return store.getters.getIfaLoading
      })

      onBeforeRouteLeave(() => {
        store.commit(Mutations.SET_IFA_LOADING, true);
        codLoaded.value = false
        discrepancyLoaded.value = false
        onlineLoaded.value = false
      })
  

        watchEffect(() => {
          if(getActiveTab.value == 'discrepancy'){
            activeLink.value = getActiveTab.value;
            if(!discrepancyLoaded.value) {
              getDiscrepancy();
            }
          }else if(getActiveTab.value == 'all'){
            activeLink.value = getActiveTab.value;
            if(!codLoaded.value) {
              getCodDirectIntrasact();
            }
          }else if (getActiveTab.value == 'online'){
            activeLink.value = getActiveTab.value;
            if(!onlineLoaded.value) {
              getOnline();
            }
          }
        });

    onUnmounted(()=> {
       store.commit(Mutations.SET_QUICK_LINK_NAVIGATION,'all')
    })
      const load = async (row, treeNode: unknown, resolve) => {
      // console.log(row);
      // let parent = false;

      // console.log(row)

      // const result = await PolicyService.getChildInvoiceByPolicy({
      //   parent_invoice_id: row.car_lead_invoice ? row.car_lead_invoice.id : null,
      // });
      resolve(row.child_invoices);
    };

            
    const invoiceListCount = computed(() => {
      return store.getters.getApprovalInvoiceCount
    })

    const refreshInvoiceListing = () => {
      fetchInvoiceList(bodyParameters);
      isError.value = false;
      store.commit(Mutations.SET_MODAL, false);
    }

    const openOnlinePaymentInfo = (invoice_id) => {
      InvoiceService.getOnlinePaymentInfo({
        online_invoice_id:invoice_id
      }).then(() => {
        InvoiceService.setOnlinePaymentInfoModal(true);
      })
        
      
    };
    
   
    return {
      openOnlinePaymentInfo,
      refreshInvoiceListing,
      invoiceListCount,
      policyModalRef,
      fetchInvoiceList,
      roles,
      load,
      getFailed,
      user,
      customFunction,
      showAction,
      activeLink,
      IFAloading,
      paginatioObject,
      currentPage,
      invoiceList,
      isError,
      loading,
      closeModal,
      ProceedModal,
      openModal,
      search,
      searchInvoice,
      onPerPageChange,
      getPaginationUrl,
      fetchInvoiceListFor,
      bodyParameters,
      fetchInvoiceListPeriod,
      resetBodyParam,
      responseResult,
      apporvePolicy,
      exportTransactionApprovals,
      disableSaveButton,
      exportUrl,
      getDiscrepancy,
      getCodDirectIntrasact,
      loadApprovalInformation,
      userPermission,
      getOnline,
      getManualOrders,
      isManualOrder,
      getActiveTab
    };
  }
});
